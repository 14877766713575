import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from './domain/page';
import { MatListOption, MatSelectionList, MatList } from '@angular/material/list';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  private subscriber: any;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) { }

  @ViewChild('transcriptionList') transcriptionList: MatSelectionList;
  @ViewChild('transcriptionSideList') transcriptionSideList: MatList;
  @ViewChild('translationSideList') translationSideList: MatList;
  @ViewChild('translationList') translationList: MatList;

  page: Page = new Page();
  selectedTranscriptIndex: Number;

  footNoteColumns: string[] = ['fnnumber', 'fntext'];
  
  
  ngOnInit(): void {
    
    this.subscriber = this.route.params.subscribe(params => {
      this.http.get('/api/journals/' + params.journalId + '/pages/' + params.pageId).subscribe((data: Page) => {
        this.page = data;
        history.replaceState({},"","/journals/"+this.page.jpjnseq+"/pages/"+this.page.jppgref)
      });
    });
  }



  onTranscriptionListChange(options: MatListOption[]) {
    this.selectedTranscriptIndex = this.page.transcripts.indexOf(options[0].value);
  }  

  onSelectionRectangleChanged(selectionIndex : number) : void {
    this.transcriptionList.deselectAll();
    //NOTE: angular does not trigger the onTranscriptionListChange event, so we manually set selectedTranscriptIndex to trigger the rectangle change 
    var selection = this.transcriptionList.options.toArray()[selectionIndex];
    this.transcriptionList.selectedOptions.select(selection);
    this.selectedTranscriptIndex = this.page.transcripts.indexOf(selection.value);
  }

  previous(): void {
    window.location.replace(this.page.prev); // Remove current page and load prev (equivalent to loading page then refreshing (slow/clunky))
    //this.router.navigate([this.page.prev]); // Can load every image if started from a page with an image but won't clear the image buffer)
  }
  next(): void {
    window.location.replace(this.page.next); // Remove current page and load prev (equivalent to loading page then refreshing (slow/clunky))
    //this.router.navigate([this.page.next]); // Can load every image if started from a page with an image but won't clear the image buffer)
  }

  onRefSelected(event): void {
    window.location.replace('journals/'+this.page.jpjnseq+'/pages/'+event.target.value) 
  }
}