<div class="landing">
  <header class="header clearfix" id="header" role="banner">
    <div
      class="flex--space-evenly"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <a
        title="visit the Western Australian Museum website"
        href="http://museum.wa.gov.au"
        rel="home"
        class="headerlogos"
      >
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/gov_logo.png"
          class="headerlogos__logo--gov"
          alt="Australian Government Coat of Arms"
        />
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/wam_logo.png"
          class="headerlogos__logo--wam"
          alt="WAM"
        />
      </a>
      <a
        title="The Kingdom of the Netherlands"
        href="https://www.netherlandsandyou.nl/your-country-and-the-netherlands/australia/about-us/consulate-in-perth"
        class=""
      >
        <img
          src="../../assets/logo-kingdomOfTheNetherlands.png"
          alt="The logo of the Kindgom of the Netherlands, this takes you to the WA Consolate page of Netherlands and you in English"
          class="headerlogos__logo--Netherlands"
        />
      </a>
    </div>
  </header>

  <main class="main-landing container-landing">
    <h1>The <i>Vergulde Draeck</i> Journals</h1>
    <p>
      The <i>Vergulde Draeck</i> was a small class of VOC vessel referred to as
      a <i>jacht</i>. The vessel had been purchased by the company in 1653 and
      was due to set sail for the Indies on 30 April. At this time, however, the
      Netherlands was engaged in the First Anglo-Dutch War (1652–54) and, at the
      request of the government, the 1653 fleet became involved in the war. The
      company was asked to assign any of their ships available to support the
      naval fleet which was blockaded in the Vlie. Negotiations between the two
      countries continued throughout the summer months. Finally, the battle of
      Ter Heide resolved the problem, with the Dutch fleet retiring severely
      damaged to the Vlie. Thus, the VOC fleet was finally able to depart on 23
      August 1653, taking the achter om route round the north of Scotland. It
      arrived at the Cape of Good Hope on 15 February 1654, departed from there
      on 12 March and finally arrived in Batavia on 24 May 1654. Later that
      year, on 7 November, the vessel set out on its return voyage to the
      Netherlands, stopping at the Cape from 19 to 28 January 1655 and arriving
      in Texel on 11 June that year. The second and final voyage of the
      <i>Vergulde Draeck</i> began with its departure from Texel on 4 October
      1655. After a stay at the Cape lasting from the 9 to 12 March 1656, the
      vessel was on the last leg of its voyage to Batavia when, on 28 April
      1656, it was wrecked on the Western Australian coast.
    </p>
    <p>
      As the months progressed, the Governor General and Council became
      concerned that the <i>Vergulde Draeck</i> had not arrived with the other
      vessels that had departed from the Cape at about the same time. Finally,
      on 7 June, the schuijt from the <i>Vergulde Draeck</i> arrived with the
      steersman and six sailors with the “sorrowful news that the abovementioned
      fine <i>jacht</i> had run aground on the coast of the Southland, on 28
      April during the night in the beginning of the morning watch, on a reef,
      stretching out in the sea for one and a half <i>mijl</i>, at the latitude
      of 30 2/3°. Of the 193 people, only 75 landed safely (among whom skipper
      Pieter Albertsz. and the mentioned under-steersman). From the ship, which
      immediately burst when touching ground, nothing was saved, and only very
      few provisions were thrown by the waves on the beach. The remaining 68
      persons were further trying to recover the boat, which was buried in the
      sand…”
    </p>

    <p>
      The Governor General and Council, noting that the ship carried 78600
      guilders in cash and 68 persons were marooned on the Southland,
      immediately commissioned the <i>fluit Witte Valck</i> and the
      <i>jacht Goede Hoop</i> to sail to rescue the survivors and recover the
      Company’s goods. Unfortunately, the ships sailed in the southern
      hemisphere winter and encountered storms on the coast of the Southland.
      The <i>Witte Valck</i> failed to make a landing and search for survivors;
      however, the <i>Goede Hoop</i> did make landing and searched several miles
      inland but saw neither wreck nor people. Instead, they lost eleven of
      their own men. Firstly, three, who seem to have lost their way in the
      bush; and, after that, another eight, who were sent ashore with the boat
      to look for them and never appeared again. Their boat was found on the
      beach smashed to pieces, during which the crew was probably lost too. That
      was the end of the first search.
    </p>
    <p>
      The Governor General and Councillors in Batavia did not have other
      suitable ships available to carry out another search for possible
      survivors, so they decided to leave it for the Commander of the VOC
      outpost at the Cape of Good Hope, Jan van Riebeeck, to make another
      attempt. This was undertaken by the <i>fluit Vincq</i> in April 1657,
      again with no success.
    </p>
    <p>
      Finally, in December 1657 it was resolved to send two vessels from Batavia
      to the Southland to search for the wreck and any survivors. The
      <i>fluit Wackende Boeij</i>, carrying a crew of 40, was skippered by
      Samuel Volkersen; and the <i>galjoot Emeloort</i>, with a crew of 25, had
      as skipper, Auke Pietersz. Jonck. Fortunately, the journals of these two
      skippers have survived. Extraordinarily a third journal of upper steersman
      Abraham Leeman of the <i>Wackende Boeij</i>, also survived. As you will
      see from the journals, Leeman was abandoned on the coast by Volkersen and
      subsequently, with 14 men, against all odds managed to return to Java in
      the ship’s boat. It is an extraordinary tale.
    </p>

    <p class="flex-start">
      More information about the
      <a
        href="http://www.museum.wa.gov.au/maritime-archaeology-db/bibliography/avoc-jacht-vergulde-draeck-wrecked-western-australia-1656"
        >Vergulde Draeck story</a
      >
      can be found at WA Museum Website.
    </p>

    <ul>
      <li><a href="/about-zeewijk">About the Zeewijk Journal</a></li>
      <li>
        <a href="/about-pelsaert">About the Pelsaert Journal</a>
      </li>
    </ul>
    <p>
      <a href="/landing-page">Overview of the journals project</a>
    </p>
    <p>
      <a href="/acknowledgements">Project acknowledgements</a>
    </p>
    <h2>Available Journals</h2>
    <div class="journal-menu">
      <a
        *ngFor="let thisJournal of allJournals"
        routerLink="/journals/{{ thisJournal.jnseq }}/pages/0"
        aria-label="Choose a journal"
      >
        {{ thisJournal.jnname }}
      </a>
    </div>
  </main>

  <div class="collections-footer footer-landing">
    <a href="http://museum.wa.gov.au/online-collections"
      >Western Australian Museum Online Collections 2021</a
    >
  </div>
</div>
