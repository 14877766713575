import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Journal } from '../journal';               // Define structure of journal data.  Make it easier later on to reference.

@Component({
  selector: 'app-about-pelsaert',
  templateUrl: './about-pelsaert.component.html',
  styleUrls: ['./about-pelsaert.component.css']
})
export class AboutPelsaertComponent implements OnInit {

  allJournals: Journal[] = [];                    // Init array of available journals
  private subscriber: any;
 
  constructor(private http: HttpClient) { }
 
  ngOnInit(): void {
    // load the list of journals.
    this.http.get<Journal[]>('/api/journals').subscribe(result => {this.allJournals = result});
  }
 
}
