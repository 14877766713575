<div class="landing">
  <header class="header clearfix" id="header" role="banner">
    <div
      class="flex--space-evenly"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <a
        title="visit the Western Australian Museum website"
        href="http://museum.wa.gov.au"
        rel="home"
        class="headerlogos"
      >
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/gov_logo.png"
          class="headerlogos__logo--gov"
          alt="Australian Government Coat of Arms"
        />
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/wam_logo.png"
          class="headerlogos__logo--wam"
          alt="WAM"
        />
      </a>
      <a
        title="The Kingdom of the Netherlands"
        href="https://www.netherlandsandyou.nl/your-country-and-the-netherlands/australia/about-us/consulate-in-perth"
        class=""
      >
        <img
          src="../../assets/logo-kingdomOfTheNetherlands.png"
          alt="The logo of the Kindgom of the Netherlands, this takes you to the WA Consolate page of Netherlands and you in English"
          class="headerlogos__logo--Netherlands"
        />
      </a>
    </div>
  </header>

  <main class="main-landing container-landing">
    <h1>Welcome to the WA Museum's interactive collection of Journals</h1>
    <p>
      This is the landing page for the Archives Project, undertaken by the
      Western Australian Museum in partnership with the Netherlands Embassy in
      Canberra and the State Archives in The Hague. The Archives Project is the
      study and presentation of the journals found detailing the journeys of the
      four Vereenigde Oostindische Compagnie (VOC or United East India Company)
      ships that have been found along the coast of Western Australia. These
      journals exist in the State Archives in the Hague, Netherlands and tell
      the story of the voyage and shipwreck of these ships.
    </p>
    <p>
      The following experience presents, on the left hand side, a transcription
      of the journals in 17th century Dutch. In the center is a high resolution
      image of the original handwritten page, and on the right hand side is a
      translation into English. Users can click on each line of the
      transcription and have the corresponding line be highlighted on the image.
      Users can also drag a mouse over the magnifying glass on the the image to
      view an enlarged portion of the text.
    </p>
    <p>
      Load times may be slow because of the high resolution images. Given the
      nature of the program, a desktop computer is the ideal way to interact
      with the journals. A
      <a class="desktop-mobile-link" href="#" aria-label="mobile friendly link"
        >mobile version</a
      >
      of the site is available, however this does limit the interactivity.
    </p>

    <p class="archive-history">
      <a href="/archive-history">Read more</a> about the Archives Project.
    </p>

    <!-- Aaron, placeholders for the new pages -->
    <ul>
      <li><a href="/about-zeewijk">About the Zeewijk Journal</a></li>
      <li><a href="/about-pelsaert">About the Pelsaert Journal</a></li>
      <li>
        <a href="/about-vergulde-draeck">About the Vergulde-Draeck Journal</a>
      </li>
    </ul>
    <p><a href="/acknowledgements">Project Acknowledgements</a></p>

    <h2>Available Journals</h2>
    <div class="journal-menu">
      <a
        *ngFor="let thisJournal of allJournals"
        routerLink="/journals/{{ thisJournal.jnseq }}/pages/0"
        aria-label="Choose a journal"
      >
        {{ thisJournal.jnname }}
      </a>
    </div>
  </main>

  <div class="collections-footer footer-landing">
    <a href="http://museum.wa.gov.au/online-collections"
      >Western Australian Museum Online Collections 2021</a
    >
  </div>
</div>
