import { FootNote } from './footnote';
import { Rectangle } from './rectangle';

export class Page
{
    jpseq: number;
    jpjnseq: number;
    jppgref: string;    
    jptranslation: string;
    transcripts: string[];
    jpimgpath: string;
    jpimgpathhigh: string;
    jpboundrect: Rectangle;
    jptranslateside: string;
    transcriptsSide: string[];
    footnotes: FootNote[];
    prev: string;
    next: string;
    reflist: string[];
    transcriptPercent: number[];
    translatePercent: number[];
    jnname: string;
    jnlanguage: string;
}