import { Component, OnInit, ViewChild, ElementRef, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { Rectangle } from '../page/domain/rectangle';
import { pathToFileURL } from 'url';

@Component({
  selector: 'app-page-image',
  templateUrl: './page-image.component.html',
  styleUrls: ['./page-image.component.css']
})
export class PageImageComponent implements OnInit {
  private _selectedLineIndex: number = -1;
  _lineCount: number;
  ratio: number;
  @Input()
  set url(url: string) {
    if(url) {
      this.source.src = url;
      this.source.onload = () => {
        this.drawImage();
        this.magnify("myImage");
      };  
    }
  } 
  

  @Input()
  set imageDimensions(imgDimensions: Rectangle) {
    if(imgDimensions) {
      this._imgDimensions = imgDimensions;
      
    }
  }

  @Input()
  set selectedLineIndex(index: number) {
    this._selectedLineIndex = index;
    if(this.context && this._selectedLineIndex >= 0) {
      this.drawImage();
      this.drawRectangle();
    }
  }
  @Input()
  set lineCount(lineCount: number) {
    this._lineCount = lineCount;
  }
  @Output() onSelected: EventEmitter<any> = new EventEmitter();


  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('div', {static: true}) div: ElementRef<HTMLDivElement>;  

  /** Canvas 2d context */
  private context: CanvasRenderingContext2D;  
  
  private _imgDimensions: Rectangle;

  source: HTMLImageElement;
  // source2: HTMLImageElement;
    

  constructor() { 
    this.source = new Image();
    // this.source2 = new Image();
  }

  ngAfterViewInit(): void {   
  }
  
  ngOnInit(): void {

    this.context = this.canvas.nativeElement.getContext('2d');
    this.canvas.nativeElement.width = this.div.nativeElement.clientWidth;
    this.canvas.nativeElement.height = this.div.nativeElement.clientHeight;
    this.canvas.nativeElement.addEventListener('click', (ev: MouseEvent) => {
      this.calculateSelectedLineIndex(ev.offsetY);
    })
    
  }

  /**
   * resizes the canvas to it's parent div width/height, then draws/refreshes the image and rectangle if a line is selected
   * @param event window resize event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) : void {
    if(this.context) {
      this.drawImage();
      this.magnify("myImage");
      if(this._selectedLineIndex >= 0) {
        this.drawRectangle();
      }
    }
  }

  /**
   * Clears, then draws a horizontally centred image onto the canvas context according to the canvas size : img size ratio
   */
  drawImage(): void {
    var c;
    this.canvas.nativeElement.width = this.div.nativeElement.clientWidth;
    this.canvas.nativeElement.height = this.div.nativeElement.clientHeight;

    var horizScale = this.canvas.nativeElement.width/this.source.width;
    var vertScale = this.canvas.nativeElement.height/this.source.height;
    this.ratio = Math.min(horizScale, vertScale); // canvas size : img size ratio

    var centerShift_x = ( this.canvas.nativeElement.width - this.source.width * this.ratio ) / 2; // shift the image to the horizontal centre of the canvas
    //var centerShift_y = ( this.canvas.nativeElement.height - this.source.height* this._ratio ) / 2; 

    //clear canvas
    this.context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    
    this.context.drawImage(this.source, 0,0, this.source.width, this.source.height, centerShift_x, 0, this.source.width * this.ratio, this.source.height * this.ratio);
    c = document.getElementById("myCanvas"); //get the canvas in a way that we can add an attribute
    c.imgID = this.source.src; //Store the SRC of the image in the canvas for access in the magnifier
    c.img = this.source;

    var horizScale = this.canvas.nativeElement.width/this._imgDimensions.imgWidth;
    var vertScale = this.canvas.nativeElement.height/this._imgDimensions.imgHeight;
    this.ratio = Math.min(horizScale, vertScale); // canvas size : img size ratio
  }

  /**
   * draws the selection rectangle
   */
  drawRectangle(): void {
    var x = 2;
    var width = this._imgDimensions.imgWidth - 4;
    var height = 2 * this._imgDimensions.height/this._lineCount;
    var y = this._selectedLineIndex * this._imgDimensions.height/this._lineCount + this._imgDimensions.y;

    this.context.beginPath();
    this.context.strokeStyle = 'orange';
    this.context.lineWidth = 2;
    this.context.rect(x * this.ratio, y * this.ratio, width * this.ratio, height * this.ratio);
    this.context.stroke();

    // this.magLine("myImage"); // Now that we have a rectangle draw it on the magnifer (this confirms if we got it correct as they should line up.)
  }
  /**
  * Calculates selectedLineIndex from mouse Y, which is the floored 'reverse' of the drawRectangle y equation
  * NOTE: this does not redraw the rectangle/image. It is expected that drawing is 1-way, i.e. the parent page component tells this component when to redraw
  * Current process is: user clicks the canvas, selectedLineIndex is calced, the parent page component is notified, it's selection list updates causing this component's
  * 'set selectedLineIndex' to be called, triggering the redraw.
  * @param mouseY coordinate offset to the canvas
  */
  calculateSelectedLineIndex(mouseY:  number): void {
    this._selectedLineIndex = Math.floor(this._lineCount * (mouseY / this.ratio - this._imgDimensions.y) / this._imgDimensions.height);
    this.onSelected.emit(this._selectedLineIndex);
  }

  // Due to being unable to draw the rectangle without needing to keep (re)creating the image, it was found to take up too much memory.
  // We therefore decided to remove the rectangle from the magnifier and since this function is the one that handled that, we have disabled the function.
  
  /**magLine(imgID) { //Modifiy magnifer to show bounding rectangle
    var d, c, mc, img, imgHigh;
    img = document.getElementById(imgID);
    d = document.getElementById("GLASS");
    c = document.getElementById("myCanvas");
    mc = document.getElementById("offscreenCanvas");
    imgHigh = document.getElementById("imgHigh");
    mc.width = this._imgDimensions.imgWidth;
    mc.height = this._imgDimensions.imgHeight;

    imgHigh.width = this._imgDimensions.imgWidth;
    imgHigh.height = this._imgDimensions.imgHeight;
    
    var mctx = mc.getContext("2d");
    mctx.clearRect(0,0,mc.width,mc.height);     // Erase the previous box (if any)
    mctx.drawImage(imgHigh, 0,0, this._imgDimensions.imgWidth,this._imgDimensions.imgHeight);     // Draw the full image onto our magnifier canvas, starting at (0,0)
    mc.testload = imgHigh.complete;
    mctx.beginPath();    // Create our selection box
    mctx.strokeStyle = "orange";
    mctx.lineWidth = 8;     // Because we're "zoomed in", make this line a little thicker 
  
    var x = 2;
    var width = this._imgDimensions.imgWidth - 4;
    var height = 2 * this._imgDimensions.height/this._lineCount;
    var y = this._selectedLineIndex * this._imgDimensions.height/this._lineCount + this._imgDimensions.y;
    //This is the box the magnifier sees
    mctx.rect(x , y , width, height); //Use the original size instead of the ratio ones since we are using the resized image
    mctx.stroke();
    var r_img = mc.toDataURL("image/png")
    d.style.backgroundImage = "url("+r_img+")";
  }*/

  magnify(imgID) { // Create the magnifier
    var img, glass, w, h, bw, d, c;
    img = document.getElementById(imgID);
    /*create magnifier glass:*/
    d = document.getElementById("GLASS");
    c = document.getElementById("myCanvas");
    if (d) { //Check if we have an existing div and remove it
      d.parentNode.removeChild(d);
      glass = document.createElement("DIV");
    }
    else { //If we do not have an existing div then create one
      glass = document.createElement("DIV");
    }
    d = document.getElementById("GLASS");
    glass.id = "GLASS";
    glass.ratio = this.ratio; //Store the ratio for access in the move magnifier
    glass.setAttribute("class", "img-magnifier-glass");
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:*/
    glass.style.backgroundImage = 'url('+c.imgID.replace('lowRez','highRez')+')';
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize =
    this._imgDimensions.imgWidth + "px " + this._imgDimensions.imgHeight + "px";
    bw = 3;
    w = glass.offsetWidth / 2.5;
    h = glass.offsetHeight / 3;
    /*execute a function when someone moves the magnifier glass over the image:*/

    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    /*and also for touch screens:*/
    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);
    
    function moveMagnifier(e) {
      var pos, x, y, c, d;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      c = document.getElementById("myCanvas"); //Fetch the canvas as we cannot call this in relation to the parent
      d = document.getElementById("GLASS"); // Fetch the glass div for the same reason

      /*prevent the magnifier glass from being positioned outside the image:*/
      x = Math.min(x,c.width);
      x = Math.max(x,0);
      y = Math.min(y,c.height);
      y = Math.max(y,0);
      /*set the position of the magnifier glass:*/
      glass.style.left = (x - w) + "px";
      glass.style.top = (y - h) + "px";
      /*display what the magnifier glass "sees":*/
      var offsetX=(Math.round(x/d.ratio) - w + bw);
      var offsetY=(Math.round(y/d.ratio) - h + bw );
      glass.style.backgroundPosition =
        "-" + offsetX + "px -" + offsetY + "px";
    }
    function getCursorPos(e) {
      var a,
        x = 0,
        y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }
}

