<div class="landing">
  <header class="header clearfix" id="header" role="banner">
    <div
      class="flex--space-evenly"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <a
        title="visit the Western Australian Museum website"
        href="http://museum.wa.gov.au"
        rel="home"
        class="headerlogos"
      >
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/gov_logo.png"
          class="headerlogos__logo--gov"
          alt="Australian Government Coat of Arms"
        />
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/wam_logo.png"
          class="headerlogos__logo--wam"
          alt="WAM"
        />
      </a>
      <a
        title="The Kingdom of the Netherlands"
        href="https://www.netherlandsandyou.nl/your-country-and-the-netherlands/australia/about-us/consulate-in-perth"
        class=""
      >
        <img
          src="../../assets/logo-kingdomOfTheNetherlands.png"
          alt="The logo of the Kindgom of the Netherlands, this takes you to the WA Consolate page of Netherlands and you in English"
          class="headerlogos__logo--Netherlands"
        />
      </a>
    </div>
  </header>

  <main class="main-landing container-landing">
    <h1>The <i>Zeewijk</i> Journals</h1>
    <p>
      The <i>Zeewijk</i> was a VOC <i>retourschip</i> that departed from
      Middelburg (Netherlands) for Batavia (the headquarters of the VOC in the
      East Indies) on 7 November 1726, with a crew of 208. The vessel was 145
      voet long (voet is the Dutch word for foot; this was probably the
      Amsterdam voet which equals 0.283 m) and carried 36 cannon (10 of 12 lb;
      20 of 6 lb; 6 of 3 lb) and 8 swivel guns. The skipper was Jan Steijns and
      the understeersman, Adriaen van der Graeff (note: spelling of peoples’
      names in the archival texts of the 18th century was flexible; Adriaen van
      der Graeff is taken from his journal, although his name is spelt
      differently elsewhere). The vessel stopped for 24 days at the Cape of Good
      Hope and departed for Batavia on 19 April 1727 with a complement of 153.
      The vessel was wrecked on Half Moon Reef in the Pelsaert Group of the
      Houtman Abrolhos on 9 June 1727. At the time of the wreck there were 137
      people on the ship of whom eventually 82 survived, finally arriving at
      Batavia on 30 April 1728.
    </p>
    <p>
      There are four surviving journals that relate to the voyage of the
      <i>Zeewijk</i>. The journals cover the voyage from the Netherlands to the
      Cape of Good Hope, and its stay there; the voyage from the Cape to its
      loss on the Houtman Abrolhos; the sojourn on the Abrolhos; and, finally,
      the voyage to Batavia in the rescue boat that the marooned survivors built
      in the Abrolhos. Two of the journals are largely identical, while the
      others cover different sets of time periods. The events from the loss of
      the ship to the survivors’ arrival in Batavia, however, is covered by all
      four. One journal, the falsified van der Graeff journal, was an exact
      duplicate of van der Graeff’s original journal other than a change made by
      the skipper, Jan Steijns, whereby he falsified the navigational
      information in an attempt to exonerate himself of responsibility for the
      loss of the ship. So, this journal is not included here. The fourth
      journal, that of an anonymous source, is held in the Biblioteèque Royale
      de Belgique in Brussels. This journal is part of a collection of
      manuscripts most probably gathered or written by the VOC-skipper Wouter
      Thomasz van Dijk in the period between 1704 and 1734. He was in Batavia
      between 9 February and 2 October 1729; and although it is not clear who
      the author was, it appears to be derived from van der Graeff’s journal.
      The first page of the journal includes a
      <a
        href="http://museum.wa.gov.au/maritime-archaeology-db/maritime-reports/mystery-missing-voc-shipwreck-houtman-abrolhos-islands-western-australia-0"
        >map</a
      >
      of the southern Pelsaert Group of the Houtman Abrolhos, showing the wreck
      on the reef and the islands that the crew visited.
    </p>

    <p>
      As the transcription and translation text cannot properly show the symbols
      used in the handwritten manuscript, text symbols have been used and are
      shown below.
    </p>

    <section class="journal-symbols" style="margin-bottom: 1rem">
      <div class="jan-steijins">
        <p class="flex-start" style="font-weight: 600; margin-bottom: 0">
          The journal of Jan Steijns is in three sections&#58;
        </p>
        <p class="flex-start">
          Cape of Good Hope to Batavia<br />
          ff NL-HaNA_1.04.02_9353_0587–0611 <br />19 April – 9 June 1727
        </p>
        <p class="flex-start">
          On the wreck, reef and Islands of Fredrick Houtman <br />ff
          NL-HaNA_1.04.02_9353_0667–0741 <br />10 June 1727–26 March 1728
        </p>
        <p class="flex-start">
          In the <i>sloep</i>, sailing from the islands to Batavia <br />ff
          NL-HaNA_1.04.02_9353_0611–0628 <br />26 March – 30 April 1728
        </p>
        <div>
          <p>
            Steijns’ journal contains a number of symbols in the margin
            text&#58;
          </p>
          <img
            style="margin: 0 auto"
            src="assets/zeewijkSymbolKey/1.png"
            alt="Journal snippet, showing a unique symbol"
          />
          <p>
            This is the symbol to indicate the death of a person. It is shown in
            the text with following symbol †. Note Steijns is much less detailed
            in this information than in the van der Graeff journal.
          </p>
        </div>
        <div class="flex-start">
          <img
            style="margin: 0 auto"
            src="assets/zeewijkSymbolKey/2.png"
            alt="Journal snippet, showing a unique symbol"
          />
          <p class="flex-start">
            MP denotes the observed position where the sun rose at the horizon.
            In the text this is indicated by MP.
          </p>
        </div>
        <div class="flex-start">
          <img
            style="margin: 0 auto"
            src="assets/zeewijkSymbolKey/3.png"
            alt="Journal snippet, showing a unique symbol"
          />
          <p class="flex-start">
            AP followed by degrees and a compass direction denotes the observed
            position where the sun set at the horizon. In the text this is
            indicated by AP.
          </p>
        </div>
      </div>
      <div class="van-der-graeff" style="margin-top: 1rem">
        <p class="flex-start" style="font-weight: 600; margin-bottom: 0">
          Journal of Adriaen van der Graeff&#58;
        </p>
        <p class="flex-start">
          Sailing from Patria to the Cape of Good Hope <br />Ff
          NL-HaNA_1.04.02_11417_0008-0088 <br />7 November 1726 – 26 March 1727
        </p>
        <p class="flex-start">
          Lying at the Roads of the Cape of Good Hope <br />ff
          NL-HaNA_1.04.02_11417_0088–0095 <br />26 March – 21 April 1727
        </p>
        <p class="flex-start">
          Sailing from the Cape of Good Hope to Batavia Ff<br />
          NL-HaNA_1.04.02_11417_0095–0128 <br />21 April – 9 June 1727
        </p>
        <p class="flex-start">
          Was on the wreck and island <br />Ff NL-HaNA_1.04.02_11417_0128–0299
          <br />10 June 1727–26 March 1728
        </p>
        <p class="flex-start">
          Van der Graeff’s journal also contains a number of symbols&#58;
        </p>
        <div>
          <img
            style="margin: 0 auto"
            src="assets/zeewijkSymbolKey/4.png"
            alt="Journal snippet, showing a unique symbol"
          />
          <p>
            This again is the death symbol; you will note that this symbol has a
            number inside it indicating numerical occurrence of the death. It is
            indicated in the text with the symbol † and the number, thus † No.
            5.
          </p>
        </div>
        <div>
          <img
            style="margin: 0 auto"
            src="assets/zeewijkSymbolKey/5.png"
            alt="Journal snippet, showing a unique symbol"
          />
          <p>
            In this case, the death symbol has additional information indicating
            that one bar shot of 12 lb was included in order to weigh the body
            in the hammock to make it sink better. The symbol in the text for
            the bar shot is &#8596; so that the image above in the text would be
            &#8596; 1 † No. 27 12 lb.
          </p>
        </div>
        <div>
          <img
            style="margin: 0 auto"
            src="assets/zeewijkSymbolKey/6.png"
            alt="Journal snippet, showing a unique symbol"
          />
          <p>
            AP followed by degrees and a compass direction denotes the observed
            position where the sun set at the horizon. In the text this is
            indicated by AP.
          </p>
        </div>
        <div>
          <img
            style="margin: 0 auto"
            src="assets/zeewijkSymbolKey/7.png"
            alt="Journal snippet, showing a unique symbol"
          />
          <p>
            MP denotes the observed position where the sun rose at the horizon.
            In the text this is indicated by MP.
          </p>
        </div>
        <div>
          <img
            style="margin: 0 auto"
            src="assets/zeewijkSymbolKey/8.png"
            alt="Journal snippet, showing a unique symbol"
          />
          <p>
            This is a combination of sunrise and sunset indicated by AMP in
            text.
          </p>
        </div>
        <div>
          <img
            style="margin: 0 auto"
            src="assets/zeewijkSymbolKey/9.png"
            alt="Journal snippet, showing a unique symbol"
          />
          <p class="flex-start">
            This is an interesting symbol which shows when the compass needle
            was adjusted. It is +indicated in the text with the following symbol
            ¥.
          </p>
        </div>
      </div>
      <div class="anonymous" style="margin-top: 1rem">
        <p class="flex-start" style="font-weight: 600; margin-bottom: 0">
          The Anonymous journal&#58;
        </p>
        <p>
          On the Island of Tortelduyff 9 June 1727 – 16 March 1728 MsII2586 Cat.
          <br />No. 7366_fol75r–94r
        </p>
        <p>
          On the <i>sloep</i> to Batavia 16 June – 21April 1728 MsII2586 Cat.
          <br />No. 7366_fol94r–96r
        </p>
        <p>
          Unlike Steijns’ and van der Graeff’s respective journals, the side
          notes do not contain navigational or death notes.
        </p>
      </div>
    </section>

    <ul>
      <li>
        <a href="/about-pelsaert">About the Pelsaert Journal</a>
      </li>
      <li>
        <a href="/about-vergulde-draeck">About the Vergulde Draeck Journal</a>
      </li>
    </ul>
    <p>
      <a href="/landing-page">Overview of the journals project</a>
    </p>
    <p>
      <a href="/acknowledgements">Project acknowledgements</a>
    </p>
    <h2>Available Journals</h2>
    <div class="journal-menu">
      <a
        *ngFor="let thisJournal of allJournals"
        routerLink="/journals/{{ thisJournal.jnseq }}/pages/0"
        aria-label="Choose a journal"
      >
        {{ thisJournal.jnname }}
      </a>
    </div>
  </main>

  <div class="collections-footer footer-landing">
    <a href="http://museum.wa.gov.au/online-collections"
      >Western Australian Museum Online Collections 2021</a
    >
  </div>
</div>
