import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Page } from '../page/domain/page';

@Pipe({ name: 'footNoteMarkupHtml', pure: false})
export class FootNoteMarkupHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(page : Page, translate : String) {
        var sanitizedInitialContent = this.sanitizer.sanitize(SecurityContext.HTML, translate);
        if(!sanitizedInitialContent) {
            return sanitizedInitialContent;
        }

        var markedUpContent = sanitizedInitialContent.replace(/\{(.*?)\}/g, str =>  {
            var fnnumber = parseInt(str.match(/\d+/)[0]);
            var footNote = page.footnotes.find(footnote => fnnumber == footnote.fnnumber);
            if(!footNote) {
                return str;
            }
            // To revert back remove <sup> | remove font-size |  replace 'block';">`+fnnumber+`</button></sup> with 'block';">Note `+fnnumber+`</button></sup>
            var content = `<sup><button class="btn btn-link btn-sm" style="vertical-align:baseline;padding:0;font-size:100%;" onclick="document.getElementById('footnoteModal`+fnnumber+`').style.display = 'block';">`+fnnumber+`</button></sup>

            <!-- The Modal -->
            <div id="footnoteModal`+fnnumber+`" class="jsModal">
            
              <!-- Modal content -->
              <div class="jsModal-content">
                <span class="jsModal-close" onclick="document.getElementById('footnoteModal`+fnnumber+`').style.display = 'none';">&times;</span>
                <h4>Note `+fnnumber+`</h4>
                <p>`+ footNote.fntext+`</p>
              </div>
            
            </div>
`
            return content;
        })
        return this.sanitizer.bypassSecurityTrustHtml(markedUpContent);        
    }
}