<div class="landing">
  <header class="header clearfix" id="header" role="banner">
    <div
      class="flex--space-evenly"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <a
        title="visit the Western Australian Museum website"
        href="http://museum.wa.gov.au"
        rel="home"
        class="headerlogos"
      >
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/gov_logo.png"
          class="headerlogos__logo--gov"
          alt="Australian Government Coat of Arms"
        />
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/wam_logo.png"
          class="headerlogos__logo--wam"
          alt="WAM"
        />
      </a>
      <a
        title="The Kingdom of the Netherlands"
        href="https://www.netherlandsandyou.nl/your-country-and-the-netherlands/australia/about-us/consulate-in-perth"
        class=""
      >
        <img
          src="../../assets/logo-kingdomOfTheNetherlands.png"
          alt="The logo of the Kindgom of the Netherlands, this takes you to the WA Consolate page of Netherlands and you in English"
          class="headerlogos__logo--Netherlands"
        />
      </a>
    </div>
  </header>

  <main class="main-landing container-landing">
    <h1>Acknowledgements</h1>
    <p class="subheading">This project has had a very long gestation.</p>
    <p>
      In the late 1970s the WA Museum held several translations of the Batavia,
      Vergulde Draeck and Zeewijk journals together with some black and white
      microfilms of the originals.
    </p>
    <p class="flex-start">
      Research by Menno Leenstra and Ruud Paesie in the Dutch archives located
      new journals and documents relating to the <i>Zeewijk</i>.
    </p>
    <p>
      By 2014, a programme was started with grants from the Netherlands Embassy
      in Canberra and the ARC Linkage Project Roaring 40s to develop a website
      to show the translation, transcription and the original document. This
      required obtaining high-resolution colour images of the original documents
      from the Dutch archives and from the Biblioteèque Royale de Belgique in
      Brussels.
    </p>
    <p>
      This enabled the transcriptions and the translations to be brought up to
      date, as knowledge and understanding of 19th Dutch maritime terms has
      steadily evolved over time. Finally, we needed to find a way of showing
      this on a website and this proved to be the most difficult part, as this
      had never been done before. Initially the Museum’s web team worked on the
      project. A Curtin University internship was also undertaken. Ultimately a
      Western Australian company, <i>Logical Developments</i>, was contracted to
      complete the project.
    </p>
    <p class="subheading">Thus, the acknowledgements are extensive&#58;</p>
    <p>
      For financial support we firstly have to thank the Embassy of the Kingdom
      of the Netherlands in Canberra, the current ambassador Her Excellency Mrs
      Marion Derckx and also the previous ambassador Her Excellency Mrs
      Anniemieke Ruigrok. In particular we would like to thank the Embassy
      staff: Femke Withag, who guided and helped us through the application
      process, and Deputy Head of Mission Erik de Feijter.
    </p>
    <p>
      The ARC Linkage Grant Roaring 40s also supported the project financially,
      but also provided links to the Nationaal Archief in The Hague.
      Particularly, Lidwien Jansen, Johan van Langen.
    </p>
    <p class="flex-start">
      For individuals who helped with the locating documents, translating and
      transcribing them&#58;
    </p>

    <ul class="acknowledgements">
      <li>In the Netherlands&#58;</li>
      <ul>
        <li>Translation and transcriptions&#58;</li>
        <ul>
          <li>Menno Leenstra and Ruud Paesie</li>
          <li>In the Nationaal Archief in The Hague&#58;</li>
          <ul>
            <li>Lidwien Jansen &amp; Johan van Langen</li>
          </ul>
          <li>At the Rijksdienst voor het Cultureel Erfgoed:</li>
          <ul>
            <li>Martijn Manders.</li>
          </ul>
        </ul>
      </ul>
      <li>In South Africa&#58; Helena Liebenberg.</li>
    </ul>
    <p>
      Finally, in Australia we are indebted to Adriaan de Jong, who has been a
      constant colleague and friend helping with the difficulties in formatting
      the documents and providing a constant source of help and advice.
    </p>
    <p>
      For development of the website, we would like to thank the WA Museum web
      team Morgan Strong, Danny Murphy and Ander Rowe who helped to develop a
      prototype in the initial stages of the project. Curtin University intern
      student Erin Jackson who further developed the initial program under the
      supervision of David McMeekin and Andrew Woods. Finally, our grateful
      thanks goes to Paul Mulroney and Logical Developments for producing this
      amazing program.
    </p>

    <ul>
      <li><a href="/about-zeewijk">About the Zeewijk Journal</a></li>
      <li><a href="/about-pelsaert">About the Pelsaert Journal</a></li>
      <li>
        <a href="/about-vergulde-draeck">About the Vergulde-Draeck Journal</a>
      </li>
    </ul>
    <p>
      <a href="/landing-page">Overview of the journals project</a>
    </p>
    <h2>Available Journals</h2>
    <div class="journal-menu">
      <a
        *ngFor="let thisJournal of allJournals"
        routerLink="/journals/{{ thisJournal.jnseq }}/pages/0"
        aria-label="Choose a journal"
      >
        {{ thisJournal.jnname }}
      </a>
    </div>
  </main>

  <div class="collections-footer footer-landing">
    <a href="http://museum.wa.gov.au/online-collections"
      >Western Australian Museum Online Collections 2021</a
    >
  </div>
</div>
