<div class="landing">
  <header class="header clearfix" id="header" role="banner">
    <div
      class="flex--space-evenly"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <a
        title="visit the Western Australian Museum website"
        href="http://museum.wa.gov.au"
        rel="home"
        class="headerlogos"
      >
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/gov_logo.png"
          class="headerlogos__logo--gov"
          alt="Australian Government Coat of Arms"
        />
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/wam_logo.png"
          class="headerlogos__logo--wam"
          alt="WAM"
        />
      </a>
      <a
        title="The Kingdom of the Netherlands"
        href="https://www.netherlandsandyou.nl/your-country-and-the-netherlands/australia/about-us/consulate-in-perth"
        class=""
      >
        <img
          src="../../assets/logo-kingdomOfTheNetherlands.png"
          alt="The logo of the Kindgom of the Netherlands, this takes you to the WA Consolate page of Netherlands and you in English"
          class="headerlogos__logo--Netherlands"
        />
      </a>
    </div>
  </header>

  <main class="main-landing container-landing">
    <h1>Introduction to the archives project</h1>
    <p>
      The VOC (Vereenigde Oostindische Compagnie, or United East India Company)
      was a Dutch trading company established in 1603 to control the trade in
      the East. The company lasted almost 200 years and sent almost 4500 ships
      with silver, in the form of coins or bars, to buy spices in the Indies.
      Four of these ships were lost on the Western Australian coast; Batavia,
      1629; Vergulde Draeck, 1656; Zuytdorp, 1713 and Zeewijk, 1727. As part of
      the administrative process of the VOC the commander and senior offices
      kept ship’s journal that recorded details of the voyage. Fortunately, the
      journals of three of these ships have survived and arrived in Batavia
      (modern Jakarta), the headquarters of the VOC in the Indies. These
      journals exist today in the State Archives in the Hague, Netherlands.
    </p>
    <p>
      The archives project started after research in the State Archives in 1973
      located the manuscripts of some of these journals. Microfilms of them were
      obtained by the WA Museum and whilst previous research had obtained
      translation of the journals, for the first time the Department of Maritime
      Archaeology was able to examine the handwritten journals and the exact
      text then provides important information about the loss of these ships. It
      should be remembered that 17th century Dutch was quite different from
      modern Dutch, as the Netherlands has rationalised the language on a number
      of occasions, so the spelling of 17th century words are often radically
      different from the modern word. In addition, as archival research has
      progressed in the Netherlands, the terminology relating to the 17th
      century nautical terms is now better understood. Finally, the journals
      that exist today are not the original journal written at the time, but a
      copy. If you examine the neatly written pages of these journals it is
      obvious that they were not written in an open boat at sea on a perilous
      voyage from Australia to Batavia. What in fact happened is that a number
      of fair copies of the journal or journals were made of any vessel arriving
      at Batavia. Some were kept at Batavia, others were sent to other VOC
      outposts, including the Cape of Good Hope and lastly copies were sent back
      to the Company in the Netherlands on separate ships, in case the
      correspondence was lost through shipwreck.
    </p>
    <p>
      Working on the original microfilms translation of the documents were made
      by various Dutch-speaking members of staff and so we began to better
      understand the things related to the loss. In fact, in the 1960s,
      Henrietta Drake Brockman, a Western Australian author had already obtained
      from the State Archives in the Netherlands a copy of the journal of
      Francisco Pelsaert, who was the commander of the ill-fated Batavia. The
      publication of this journal in her book Voyage to Disaster lead to the
      discover of the wreck in 1963. Four journals were found relating to the
      loss of the Zeewijk and three journals kept by the vessels searching for
      the Vergulde Draeck. Interestingly, no journals were ever found relating
      to the Zuytdorp as there were no survivors and the first knowledge of the
      loss was in the 1950s when traces of the ship were discovered on what is
      now known as the Zuytdorp Cliffs north of the town of Kalbarri.
    </p>
    <p>
      In 2016 an application was made under the Embassy of the Kingdom of the
      Netherlands in Canberra, Shared Cultural Heritage Initiative 2015–16 to
      develop a website to show the ‘original’ hand-written journals alongside a
      transcription and translation of the pages so that people could compare
      the text. We worked with the staff of the National Archives to obtain high
      quality colour images of the pages of the journals and commissioned
      transcriptions and translations of the journals, utilising Dutch
      archivists in the Netherlands, Cape Town and Perth.
    </p>
    <p>
      However, the most difficult problem was to find a system to show the pages
      and the text together. In 2019 we located a Perth company, Logical
      Developments, that were able to produce a method of presenting what we
      wanted. Through an ingenious system, a website was produced that shows the
      transcription on the left-hand side, the original journal in the centre
      and on the right the translation. By clicking on a line of the
      transcription, the approximate position is highlighted in yellow on the
      journal page. The magnifying glass can be moved around the journal page to
      see an enlarged version of the handwritten text. Footnotes are also
      available to explain technical terms.
    </p>
    <p>
      Of the three shipwreck journals, Batavia has one - Francisco Pelsaert’s
      Journal. Three journals exist relating to the search for the survivors of
      the Vergulde Draeck: Samuel Volkersen’s journal of voyage of the Waeckende
      Boeij, Aucke Pieters Jonck’s journal of the Emeloordt and Abraham Leeman’s
      journal of his voyage in a small boat from Australia to Batavia.
      Lastly,the Zeewijk that has four: the journal of the skipper Jan Steijns;
      two journals of Adriaen van der Graeff and an anonymous journal.
    </p>

    <h2>Available Journals</h2>
    <div class="journal-menu">
      <a
        *ngFor="let thisJournal of allJournals"
        routerLink="/journals/{{ thisJournal.jnseq }}/pages/0"
        aria-label="Choose a journal"
      >
        {{ thisJournal.jnname }}
      </a>
    </div>
  </main>

  <div class="collections-footer footer-landing">
    <a href="http://museum.wa.gov.au/online-collections"
      >Western Australian Museum Online Collections 2021</a
    >
  </div>
</div>
